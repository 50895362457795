import { Fragment } from "react";
// @ts-ignore
import { Outlet } from "react-router-dom";
// @ts-ignore
import { useNavigate } from "react-router-dom";

import {
  Header,
  HeaderContainer,
  LogoContainer,
  Logo,
  NavLinks,
  NavLink,
  Footer,
  FooterContainer,
  LogoFooter,
  Copyright,
  FooterLinkContainer,
  FooterLink,
} from "./layout.styles";

import {
  EquipmentApi,
  PrivacyPolicy,
  UserAgreement,
  WelcomeEmail,
  DirectMailerCampaigns,
} from "./files";

const Layout = () => {
  const navigate = useNavigate();

  const mailToHandler = (e) => {
    e.preventDefault();
    window.location.href = "mailto:no-reply@tpfsports.com.au";
  };

  const logoHandler = () => {
    navigate("/");
  };

  const fileUrl = {
    EquipmentApi,
    PrivacyPolicy,
    UserAgreement,
    DirectMailerCampaigns,
    WelcomeEmail,
  };

  const handleDownloadFilesAtFooter = (fileUrl) => {
    if (fileUrl.length === 0) return;
    console.log(fileUrl);
    const fileName = fileUrl.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = fileUrl;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <Fragment>
      <Header>
        <HeaderContainer>
          <LogoContainer onClick={logoHandler}>
            <Logo />
          </LogoContainer>
          <NavLinks>
            <NavLink to="#" onClick={mailToHandler}>
              <div>Let's talk!</div>
            </NavLink>
          </NavLinks>
        </HeaderContainer>
      </Header>
      <Outlet />
      <Footer>
        <FooterContainer>
          <LogoContainer onClick={logoHandler}>
            <LogoFooter />
          </LogoContainer>
          <Copyright>
            Mber+<sup>®</sup>&nbsp;is a registered trademark
          </Copyright>
        </FooterContainer>
        <FooterLinkContainer>
          <FooterLink
            to="#"
            onClick={() => handleDownloadFilesAtFooter(fileUrl.EquipmentApi)}
          >
            API TERMS
          </FooterLink>
          <FooterLink
            to="#"
            onClick={() => handleDownloadFilesAtFooter(fileUrl.UserAgreement)}
          >
            Mber+® USER AGREEMENT
          </FooterLink>
          <FooterLink
            to="#"
            onClick={() => handleDownloadFilesAtFooter(fileUrl.PrivacyPolicy)}
          >
            PRIVACY POLICY
          </FooterLink>
        </FooterLinkContainer>
      </Footer>
    </Fragment>
  );
};

export default Layout;
